import $ from 'jquery';
import svg4everybody from 'svg4everybody';
import objectFitImages from 'object-fit-images';
import flexibility from 'flexibility';
import {TweenMax, Power2, TimelineLite} from "gsap/TweenMax";
import isMobile from './modules/common/is-mobile.js';
import './modules/common/lazyload.js';
import './modules/common/userAgent.js';
import './modules/common/tablefix.js';
import './modules/common/smoothScroll.js';
import './plugins/delighters.min.js';
// import './plugins/jquery.fullscreenmenu.js';

flexibility(document.documentElement);//flexboxのie対応
objectFitImages();//object-fitのie対応
svg4everybody();//svgをieで表示

//電話番号スマホのみ掛ける
$("a[href*='tel']").on("click", function(e){
  if(!isMobile(768)){
    return false
  }
});

const hoge = val => val + 1;
console.log(hoge(1));

$(function() {
  var $win = $(window),
      $header = $('#l-header'),
      animationClass = 'is-animation';

  $win.on('load scroll', function() {
    var value = $(this).scrollTop();
    if ( value > 100 ) {
      $header.addClass(animationClass);
    } else {
      $header.removeClass(animationClass);
    }
  });

  //グローバルナビのカレント設定
  // ページurlを"/"で区切った時の文字列を取得
  var currentCheckUrl = location.pathname.split("/")[1];
  var targetNav = $(".l-header-globalNav__mainItem");
  targetNav.each(function(){
    var href = $(this).find("a:first").attr("href").split("/")[1];

    if( href == currentCheckUrl ) {
      $(this).addClass("is-current");
    };
  });

  //windowサイズでモバイル判定
  // var isMobile = function(){
  //   return $(window).width() <= 768 ? true : false
  // }

  //スマホで画面の高さ取得・設定
  // $(window).on('resize', function(){
  //   if(isMobile()) {
  //     $('#hero').css({
  //       'height': $(window).height()
  //     });
  //   } else {
  //     $('#hero').removeAttr('style');
  //   }
  // }).trigger('resize');
});


// header
$(function(){
  var spBreakPoint = 989;
  var sethover;
  var setleave;
  var nowActive = -1;
  var hoverClass = 'hover';
  $(window).on('resize load', function(){
    $('#l-header-globalNav > ul > li').off();

    if(spBreakPoint <= $(window).width()) {
      //PC用ホバー時に内容表示
      $("#l-header-globalNav > ul > li").on({
        "mouseenter": function(){
          var targetOn = $(this);
          clearTimeout(setleave);
          sethover = setTimeout(function(){
            console.log("h2");
            $(targetOn).addClass('hover').find('.l-header-megaMenu').show();
            $("#l-header-globalNav > ul > li").not($(targetOn)).removeClass('hover').find('.l-header-megaMenu').hide();
            nowActive = $(targetOn).index();
          },200);
        },
        "mouseleave": function(){
          var targetOff = $(this);
          clearTimeout(sethover);
          setleave = setTimeout(function(){
            console.log("l2");
            $(targetOff).find('.l-header-megaMenu').hide();
            $(targetOff).removeClass('hover');
            nowActive = -1;
          },200);
        }
      });

    } else {
      // メニューを開く
      $('#l-menu-btn__sp').off();
      $('#l-menu-btn__sp').add('#l-header-globalNav > ul > li a').on('click', function(){
        var currentPosition;
        if(!($("body").hasClass("is-open"))) {
          $("body").removeClass("is-close").addClass("is-open");
          // 現在のスクロール位置を取得
          currentPosition = $( window ).scrollTop();
          $("#l-header-globalNav").fadeIn();
          $('body,html').css({"position":"fixed","top": -1 * currentPosition});
        } else {
          $("body").removeClass("is-open").addClass("is-close");
          $('body,html').css({"position":"static","overflow":"visible","height":"auto"});
          // メニューを開いた時点のスクロール位置を反映
          $( 'html, body' ).prop( { scrollTop: currentPosition } );
          $('#l-header-globalNav').fadeOut();
        }
      });
      //スマホ用メニュー内アコーディオン
      $('#l-header-globalNav ul li .childOpenBtn').off();
      $("#l-header-globalNav ul li .childOpenBtn").on('click', function(){
        $(this).next().slideToggle();
        $(this).toggleClass("is-open");
      });
    }
  });

  //別ページからのアンカーリンク対応
  var winWidth = $(window).width();
  var topMargin = winWidth >= 768 ? 120 : 80;
  var target = location.hash;
  if(target.length) {
    $(window).on('load', function(){
      var position = $(target).offset().top - topMargin;
      //   console.log(position);
      $("html, body").animate({scrollTop:position}, 100, "swing");
      if($(target).children('.subInner').css('display') == 'none'){
        // 同時に開閉イベントを実行
        $(target).children('h2').trigger('click');
      }
    });
  }
});

//pagetopの表示
$(function() {
    var topBtn = $('.c-scrollPageTop');    
    topBtn.hide();
    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            topBtn.fadeIn();
        } else {
            topBtn.fadeOut();
        }
    });
});

